.timeLeftLabel {
  font-family: var(--display-font);
  margin-bottom: 0.2rem;
  font-size: 1.2rem;
  display: flex;
}

.timeLeftIcon {
  margin-right: 0.3rem;
}

.bar {
  width: 100%;
  height: 15px;
  border: 3px solid var(--secondary-color);
  background-color: var(--secondary-color);
  box-sizing: border-box;
}

.progressIndicator {
  height: 100%;
  background-color: var(--background-color);
  transform-origin: left;
}
