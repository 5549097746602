.styledBtn {
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  background-color: transparent;
  border: none;
  margin: 0.5rem 0;
  line-height: 1em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1em;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}
.underline {
  display: block;
  height: 100%;
  width: 100%;
  z-index: -1;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}
.text {
  z-index: 0;
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
}
.rightText {
  padding-left: 0.2em;
}
.leftText {
  padding-right: 0.2em;
}
