.container {
  padding: 0.3rem 0.5rem;
  border-radius: 10px;
  border-width: 4px;
  border-style: solid;
  cursor: pointer;

  &[data-state="initial"],
  &[data-state="selected"] {
    border-color: var(--primary-color);
  }

  &[data-state="selected"] {
    background-color: var(--primary-color);
  }

  &[data-state="correct"] {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }

  &[data-state="incorrect"] {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
  }
}

.answerText {
  margin: 0;
  // padding: 0.5rem 0;
  font-weight: bold;
  display: flex;

  span {
    align-self: center;
  }

  .questionIndex {
    padding-right: 1rem;
    font-size: 2.3rem;
    line-height: 2rem;
    font-family: "Righteous", cursive;

    [data-state="initial"] & {
      color: var(--secondary-color);
    }

    [data-state="selected"] &,
    [data-state="correct"] &,
    [data-state="incorrect"] & {
      color: white;
    }
  }
}
