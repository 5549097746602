.parentContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  max-width: 100vw;
  overflow-x: hidden;
}

.container {
  padding: 1rem 1rem 0;
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}

.timerBar {
  margin-bottom: 1rem;
}

.questionContainer {
  border: 5px solid var(--text-color);
  padding: 0.4rem 0.6rem;
  background-color: white;
}

.questionText {
  font-size: 1rem;
  line-height: 1.3rem;
  display: flex;
  .questionNo {
    font-family: var(--display-font);
    align-self: flex-start;
    color: var(--secondary-color);
    font-size: 3rem;
    padding: 0.3rem;
    margin-right: 1rem;
    line-height: 1rem;
  }
}

.answerList {
  padding: 0;
  & > * {
    margin: 0.5rem 0;
  }
}
