.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 2rem 1rem 0;
  width: 100%;
  box-sizing: border-box;
}

.headerBtn {
  cursor: pointer;
}

.logoContainer {
  width: 150px;
}

.bigLogoContainer {
  width: 250px;
}

.logo {
}

.icon {
  height: 1rem;
}
