.container {
  border-radius: 5px;
  border: none;
  background-color: var(--primary-color);
  color: white;
  padding: 0.6rem 0.5rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}
