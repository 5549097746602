.resultCanvas {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1.6rem 1rem 0;
  width: 100%;
  box-sizing: border-box;
}

.dateParagraph {
  text-align: center;
  font-size: 1.7rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;

  .timeDate {
    font-size: 1.1em;
    font-family: var(--display-font);
  }
}
.streak {
  font-family: var(--display-font);
  font-size: 2rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--secondary-color);
}
.streakIcon {
  height: 2rem;
  width: 2rem;
}

.result {
  font-family: var(--display-font);
  font-size: 3rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resultIcon {
  height: 3rem;
  width: 3rem;
  margin-right: 1.3rem;
}

.resultContainer {
  list-style: none;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 0;
}

.resultBlock {
  color: white;
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: var(--display-font);
  width: 100%;
  padding: 0.1rem 0.5rem;
  box-sizing: border-box;
}

.correctIcon {
  width: 4rem;
  height: 4rem;
}

.correct {
  background: var(--primary-color);
}

.incorrect {
  background: var(--secondary-color);
}

.buttonList {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding: 0.5rem 0;
}

.button {
  margin: 0 10px;
}

@media only screen and (max-width: 700px) {
  .resultContainer {
    list-style: none;
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 0.5rem;
    width: 100%;
  }

  .resultBlock {
    font-size: 3rem;
  }

  .correctIcon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .buttonlist {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
}
