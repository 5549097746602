@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&family=Righteous&display=swap");

body {
  margin: 0;
  font-family: "Noto Sans TC", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
}

html {
  --primary-color: #2c82c0;
  --secondary-color: #d64a4c;
  --background-color: #fffae5;
  --text-color: #343434;
  --max-width: 1100px;
  --display-font: "Righteous", cursive;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 14pt;
}
