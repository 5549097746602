.container {
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
  padding: 0 1.1rem;
  box-sizing: border-box;
}
.backButton {
  font-size: 1.5rem;
}
.ruleList {
  padding: 0;
  margin-bottom: 2rem;
  li > span {
    background-color: var(--secondary-color);
    line-height: 1.7rem;
    color: #ffffff;
    font-weight: 500;
  }
  li {
    list-style-type: none;
    margin-bottom: 0.1rem;
  }
}
