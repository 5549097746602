.rootContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  box-sizing: border-box;
}
@supports (height: 100dvh) {
  .rootContainer {
    min-height: 100dvh;
  }
}
