.container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;
  box-sizing: border-box;
}

.logoContainer {
  width: 180px;
  margin: 0 auto;
  padding-top: 2rem;
}

.dateString {
  font-weight: 500;
  font-family: var(--display-font);
  font-size: 1.1em;
}

.dateParagraph {
  font-weight: 500;
}

.gameRuleHeader {
  font-size: 1rem;
  color: var(--secondary-color);
}

.desc {
  margin-top: 2rem;
  line-height: 2rem;
  font-weight: 500;
  width: 80%;
  color: #ffffff;
}

.hightlight {
  background-color: var(--primary-color);
}

.actionsContainer {
  padding-bottom: 3rem;
}

.secondaryButton {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.playButton {
  font-size: 2rem;
}
.actionRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
